import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdArrowDownward } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Японська Кулінарна Академія: Смак Східного Сонця
			</title>
			<meta name={"description"} content={"Смак Східного Сонця – Гастрономічні Подорожі до Світу Японської Кухні"} />
			<meta property={"og:title"} content={"Home | Японська Кулінарна Академія: Смак Східного Сонця"} />
			<meta property={"og:description"} content={"Смак Східного Сонця – Гастрономічні Подорожі до Світу Японської Кухні"} />
			<link rel={"shortcut icon"} href={"https://ritas-flet.net/img/5900160.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ritas-flet.net/img/5900160.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ritas-flet.net/img/5900160.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ritas-flet.net/img/5900160.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ritas-flet.net/img/5900160.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ritas-flet.net/img/5900160.png"} />
		</Helmet>
		<Components.Header />
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://ritas-flet.net/img/top-view-chopsticks-with-sushi_23-2149482786.jpg) center/cover"
			padding="60px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Box margin="-16px -16px -16px -16px" display="flex" min-height="500px" width="100%">
				<Box
					display="block"
					padding="16px 16px 16px 16px"
					md-width="100%"
					margin="80px 0px 0px 0px"
					width="100%"
				>
					<Box display="flex" flex-direction="column" text-align="center">
						<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
							Японська Кулінарна Академія
						</Text>
						<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0">
							Смак Східного Сонця
						</Text>
					</Box>
				</Box>
			</Box>
			<Box text-align="center" margin="96px 0 0 0">
				<Text margin="8px 0" text-transform="uppercase">
					Про нас
				</Text>
				<Icon category="md" margin="0 auto" icon={MdArrowDownward} />
			</Box>
		</Section>
		<Section
			text-align="center"
			background-color="--primary"
			color="--light"
			padding="80px 0"
			sm-padding="40px 0"
		>
			<Text
				as="h5"
				font="--lead"
				margin="10px 0 0 0"
				text-transform="uppercase"
				letter-spacing="5px"
			>
				Подорожі до Світу Японської Кухні
			</Text>
			<Text as="h1" font="normal 900 50px/1.2 --fontFamily-sans" md-font="--headline2" margin="10px 0 0 0">
				Про нас
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
				Ласкаво просимо до "Японської Кулінарної Академії: Смак Східного Сонця" - захоплюючий кулінарний курс, який запрошує вас на захоплюючу подорож у світ традиційної японської кухні та її культурного коріння. Наші досвідчені кухарі та інструктори допоможуть вам розкрити секрети приготування найвишуканіших і найсмачніших.
			</Text>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="space-between"
				display="flex"
				width="50%"
			>
				<Text margin="0px 0px 0px 0px" color="--grey" font="--lead" lg-text-align="center">
					Наші курси надихаються багатою історією японської кулінарії та пропонують учням заглибитись у світ смаків, традицій та секретів приготування страв Японії. Ми пропонуємо унікальне занурення в мистецтво приготування японських страв, від традиційних суші та сашими до вишуканих рамен та теппаньяки.{"\n\n"}
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://ritas-flet.net/img/man-preparing-sushi-order-takeaway_23-2149050373.jpg"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box
				padding="80px 72px 80px 72px"
				background="--color-grey"
				border-radius="24px"
				margin="0px 0px 64px 0px"
				sm-padding="60px 36px 60px 36px"
			>
				<Box
					display="flex"
					flex-direction="column"
					md-width="100%"
					md-align-items="center"
					md-justify-content="center"
					md-text-align="center"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 25px/1.5 --fontFamily-sans"
						color="--dark"
						md-text-align="center"
						sm-font="normal 700 22px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Приєднайтесь до нас і перенесіться у світ смаку та культури Японії. Наші курси допоможуть вам розширити кулінарні горизонти, навчать готувати автентичні страви та подарують незабутні враження від участі у цій кулінарній подорожі.
					</Text>
				</Box>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="36px 4%"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="12px"
			>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-grey"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						1
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Майстерність приготування
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Наші курси пропонують практичні навички приготування японських страв, починаючи від базових рецептів і закінчуючи вишуканими кулінарними шедеврами.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 30px 0px">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-grey"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						2
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Занурення в культуру
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Ми розповімо про традиції та звичаї Японії, пов'язані з їжею. Ви дізнаєтеся про церемонію чаю, свята та особливі моменти, пов'язані з прийомом їжі.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-grey"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						3
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Інгредієнти та обладнання
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Ми допоможемо вам розібратися з вибором інгредієнтів та особливостями необхідного кулінарного обладнання.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 0px 0px">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-grey"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						4
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Практика та знайомства
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Під час курсів ви зможете готувати разом з нашими інструкторами, обмінюватися досвідом з іншими студентами та насолоджуватися результатами вашої роботи у затишній атмосфері.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6523ace1e2dd3a0022204083"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});